import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import FontAwesomeIcon from "./utilities/fontawesome-icons";

import  './assets/css/main.css';

axios.defaults.baseURL = 'http://'+window.location.hostname+':1337';

createApp(App).component("font-awesome-icon", FontAwesomeIcon).use(store).use(router, axios).mount('#app');