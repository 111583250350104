<template>
  <div class="wrapper is-flex is-flex-direction-column">
    <nav class="navbar p-2 flex-fixed" style="border-bottom: 1px solid #c9d2e3;">
      <div class="navbar-brand">
        <router-link to="/" class="navbar-item is-size-5">Retina DeepAI</router-link>
  
        <a class="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navbar-menu" @click="showMobileMenu = !showMobileMenu">
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>
      <div class="navbar-menu" id="navbar-menu" v-bind:class="{'is-active': showMobileMenu }">
        <div class="navbar-end">
          <router-link to="/" class="navbar-item">Главная</router-link>
          <router-link to="/demo" class="navbar-item">Демо</router-link>
        </div>
      </div>
    </nav>

    <section class="is-flex flex-stretch is-flex-direction-column">
      <router-view/>
    </section>

    <footer class="pr-2 pl-2 flex-fixed">
      <div class="is-flex is-justify-content-space-between">
        <div class="is-flex is-flex-wrap-wrap">
          <router-link class="mr-4 ml-4 footer-link mb-3" to="/feedback">Обратная связь</router-link>
          <router-link class="mr-4 ml-4 footer-link mb-3" to="/terms">Политика использования</router-link>
          <router-link class="mr-4 ml-4 footer-link mb-3" to="/api">Интеграция</router-link>
          <router-link class="mr-4 ml-4 footer-link mb-3" to="/acknowledgement">Благодарность</router-link>
          <router-link class="mr-4 ml-4 footer-link mb-3" to="/license">Партнерство</router-link>
        </div>
        <div class="is-flex is-flex-shrink-0">
          <a class="footer-logo is-flex is-align-items-center ml-4 mr-4 mb-3 footer-link" href="/">
            <img class="footer__osudeepai-logo" src="@/assets/osudeepai-logo-short.png" alt="OSU DeepAI">
            <span style="font-weight:600; font-size:14px; white-space:nowrap;">OSU DeepAI</span>
          </a>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data() {
    return {
      showMobileMenu: false,
    }
  },
}
</script>

<style lang="scss">
@import '../node_modules/bulma';

.wrapper {
  min-height: 100vh;
}

.flex-stretch {
  flex: 1 1 auto;
}

.flex-fixed {
  flex: 0 0 auto; 
}

.footer-link {
  text-decoration: none;
  color: #2d2f32;
}

footer {
  font-size: 14px;
}

.footer__osudeepai-logo {
  width: 18px;
  object-fit: contain;
  margin-right: 4px;
}


</style>
