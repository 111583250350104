import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: 'Retina DeepAI',
    },
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/acknowledgement',
    name: 'acknowledgement',
    component: () => import(/* webpackChunkName: "about" */ '../views/AcknowledgementView.vue'),
    meta: {
      title: 'Благодарность',
    },
  },
  {
    path: '/api',
    name: 'api',
    component: () => import(/* webpackChunkName: "about" */ '../views/ApiView.vue'),
    meta: {
      title: 'Интеграция',
    },
  },
  {
    path: '/demo',
    name: 'demo',
    component: () => import(/* webpackChunkName: "about" */ '../views/DemoView.vue'),
    meta: {
      title: 'Демо',
    },
  },
  {
    path: '/feedback',
    name: 'feedback',
    component: () => import(/* webpackChunkName: "about" */ '../views/FeedbackView.vue'),
    meta: {
      title: 'Обратная связь',
    },
  },
  {
    path: '/license',
    name: 'license',
    component: () => import('../views/LicenseView.vue'),
    meta: {
      title: 'Партнерство',
    },
  },
  {
    path: '/terms',
    name: 'terms',
    component: () => import(/* webpackChunkName: "about" */ '../views/TermsView.vue'),
    meta: {
      title: 'Политика использования',
    },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from) => {
  document.title = to.meta?.title ?? 'Retina DeepAI'
})

export default router
