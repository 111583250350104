<template>
  <div class="home">
    <div id="hero" class="hero is-medium mb-6">
      <div class="hero-body has-text-centered hero-container">
        <p class="hero-title title mb-6 is-size-2">
          Добро пожаловать на <span>Retina DeepAI</span>
        </p>
        <p class="hero-subtitle subtitle">
          Помогаем анализировать ОКТ изображения с помощью искусственного интеллекта
        </p>
        <router-link to="/demo" class="button pl-5 pr-5 is-link">Попробовать</router-link>
      </div>
    </div>

    <section class="how-its-work mb-6">
      <div class="container mb-5">
        <p class="is-size-4 has-text-centered how-its-work__title pb-3">Как это работает?</p>
      </div>
      <div class="container">
        <div class="is-flex is-flex-wrap-wrap is-justify-content-center">
            <div class="how-its-work__video">
                <iframe class="how-its-work__video-frame" src="https://www.youtube.com/embed/n--7CbREbuE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>   
            </div>    
        </div>
      </div>
    </section>

    <section class="what-we-do mb-6">
      <div class="container mb-5">
        <p class="is-size-4 has-text-centered what-we-do__title pb-3">Что мы делаем?</p>
      </div>
      <div class="container has-text-centered mb-3">
        <p style="font-size: 16px;">Для анализа OКТ изображений сетчатки глаза мы используем модели нейронных сетей для решения следующих задач</p>
      </div>
      <div class="container">
        <div class="is-flex is-flex-wrap-wrap is-justify-content-center">
          <router-link to="/demo" custom v-slot="{ navigate }">          
            <div role="link" @click="navigate" class="is-flex is-align-items-stretch m-2">
              <div class="what-we-do__box">
                  <div class="what-we-do__box-icon"><font-awesome-icon icon="fa-solid fa-vector-square" /></div>
                  <div class="what-we-do__box-title">Детекция</div>
                  <p>Определяем все срезы ОКТ сетчатки глаза на изображении для более комплексного анализа</p>
              </div>
            </div>
          </router-link>
          
          <router-link to="/demo" custom v-slot="{ navigate }"> 
            <div role="link" @click="navigate" class="is-flex is-align-items-stretch m-2">
              <div class="what-we-do__box">
                <div class="what-we-do__box-icon"><font-awesome-icon icon="fa-solid fa-eye" /></div>
                <div class="what-we-do__box-title">Анализ</div>
                <p>Каждый фрагмент ОКТ изображения анализируется по более чем 100 признаков с последующей генерацией отчета</p>
              </div>
            </div>
          </router-link>
        
          <router-link to="/demo" custom v-slot="{ navigate }"> 
            <div role="link" @click="navigate" class="is-flex is-align-items-stretch m-2">
              <div class="what-we-do__box">
                <div class="what-we-do__box-icon"><font-awesome-icon icon="fa-solid fa-chart-area" /></div>
                <div class="what-we-do__box-title">Визуализация</div>
                <p>Интрепретация полученных результатов с помощью выделения границ и областей найденных маркеров на изображении</p>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  components: {

  }
}
</script>

<style scoped>
  #hero {
    width: 100%;
    height: 65vh;
    background: url("../assets/img/hero-bg.jpg") center center;
    background-size: cover;
    position: relative;
    padding: 0;
    min-height: 26rem;
  }

  #hero .hero-body {
    padding: 8rem 4.5rem;
  }
  
  #hero:before {
    content: "";
    background: rgba(56, 64, 70, 0.75);
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
  }
  
  #hero .hero-container {
    z-index: 2;
  }
  
  #hero .hero-title {
    margin: 0 0 10px 0;
    line-height: 56px;
    color: #fff;
  }
  
  #hero .hero-title span {
    border-bottom: 4px solid #485fc7;
  }
  
  #hero .hero-subtitle {
    color: rgba(255, 255, 255, 0.8);
  }
  
  @media (max-width: 992px) {
    #hero {
      height: calc(100vh - 70px);
    }
  }

  .what-we-do__title::after, .how-its-work__title::after {
    content: "";
    position: absolute;
    display: block;
    width: 40px;
    height: 3px;
    background: #485fc7;
    bottom: 0;
    left: calc(50% - 20px);
  }

  .what-we-do__title::before, .how-its-work__title::before {
    content: "";
    position: absolute;
    display: block;
    width: 120px;
    height: 1px;
    background: #ddd;
    bottom: 1px;
    left: calc(50% - 60px);
  }

  .what-we-do__box {
    text-align: center;
    padding: 30px 20px;
    transition: all ease-in-out 0.3s;
    background: #fff;
    max-width: 400px;
  }
  
  .what-we-do__box .what-we-do__box-icon {
    margin: 0 auto;
    width: 64px;
    height: 64px;
    background: #c8d1f9;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    transition: ease-in-out 0.3s;
    color: #485fc7;
    font-size: 24px;
  }

  .what-we-do__box-title {
    margin-bottom: 8px;
    font-size: 18px;
    font-weight: 500;
  }
  
  .what-we-do__box .what-we-do__box-icon i {
    color: #485fc7;
    font-size: 28px;
  }
  
  .what-we-do__box h4 a {
    color: #384046;
    transition: ease-in-out 0.3s;
  }
  
  .what-we-do__box p {
    line-height: 22px;
    font-size: 14px;
    margin-bottom: 0;
  }
  
  .what-we-do__box:hover {
    border-color: #fff;
    box-shadow: 0px 0 25px 0 rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }
  
  .what-we-do__box:hover .what-we-do__box-title,
  .what-we-do__box:hover .what-we-do__box-icon i {
    color: #545fe5;
  }

  .how-its-work__video-frame {
    width: 640px;
    height: 344px;
  }

  @media (max-width: 992px) {
    .how-its-work__video-frame {
        width:560px; 
        height:315px;
      }
  }
  
  @media (max-width: 680px) {
 
    .how-its-work__video-frame {
        width: 330px;
        height: 200px;
      }
  }
</style>
